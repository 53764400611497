body {
  background-color: green;
  -webkit-font-smoothing: antialiased;
}

body,
a,
a:visited {
  color: white;
}

hr {
  background-color: white;
  height: 1px;
  border: none;
  margin: 0.25em 0;
}

.equation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  letter-spacing: 3px;
  font-family: sans-serif;
  font-size: 50px;
  text-align: right;
  color: white;
  line-height: 1.25;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.line {
  white-space: nowrap;
}

@media (max-width: 640px) {
  .equation {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .equation {
    font-size: 20px;
  }
}
